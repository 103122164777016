import { config } from "~/global.config";
import { pipe } from "ramda";
import { TheDay } from "~/_libs/time/TheDay";

const { origin, originPay } = config ?? {};

const withOrigin = (path: string) => new URL(path, origin);
const withOriginPay = (path: string) => new URL(path, originPay);

const withSearch = (url: URL) => {
  if (typeof window !== "undefined") {
    const searchParams = new URLSearchParams({
      ...Object.fromEntries(url.searchParams),
      ...Object.fromEntries(new URLSearchParams(window.location.search)),
    });

    url.search = searchParams.toString();
  }

  return url;
};

const withHash = (url: URL) => {
  if (typeof window !== "undefined") {
    url.hash = url.hash || window.location.hash;
  }

  return url;
};

const withReturnTo = (url: URL) => (returnTo?: string) => {
  if (returnTo) {
    url.searchParams.set("returnTo", `${getUrl(returnTo)}`);
  }

  return url;
};

export const getUrl = pipe(withOrigin, withSearch, withHash);
export const getUrlPay = pipe(withOriginPay, withSearch, withHash);

export const getRoute = (route: string) => {
  const url = getUrl(route);
  return url.href.slice(url.origin.length);
};

export const getAddCardNewWindowUrl = getUrlPay(
  "/guest/account/payments/add-card?newWindow=true",
);

export const getLogInUrl = withReturnTo(getUrl("/auth/login"));

export const getLogOutUrl = () => withReturnTo(getUrl("/auth/logout"))("/");

export const getSignUpUrl = () =>
  withReturnTo(getUrl("/auth/signup"))("/guest/onboarding");

export const get500Url = withReturnTo(getUrl("/guest/500"));

export const getOnboardingUrl = withReturnTo(getUrl("/guest/onboarding"));

export const getWelcomeUrl = withReturnTo(getUrl("/guest/welcome"));

export const getHomeUrl = withReturnTo(getUrl("/guest/home"));

export const getWorkspaceUrl = (id: string, day: TheDay) =>
  withReturnTo(getUrl(`/guest/workspace/${id}?startTime=${day.toString()}`));

export const getBookUrl = withReturnTo(getUrl(`/guest/book`));

export const getSearchUrl = withReturnTo(getUrl(`/guest/search`));
